<script lang="ts" setup>
import howItWorksLaptop from '@consumer/assets/images/how-it-works-laptop.png'
import howItWorksMailbox from '@consumer/assets/images/how-it-works-mailbox.png'
import howItWorksPrinter from '@consumer/assets/images/how-it-works-printer.png'

const howItWorksLaptopUrl = computed(() => `url(${howItWorksLaptop})`)
const howItWorksMailboxUrl = computed(() => `url(${howItWorksMailbox})`)
const howItWorksPrinterUrl = computed(() => `url(${howItWorksPrinter})`)
</script>

<template>
  <GContainer
    class="flex flex-col lg:flex-row justify-between gap-6"
  >
    <GCard rounded shadow class="browse-delivery-method browse-send-by-email-text">
      <div class="browse-delivery-method-header"/>
      <div class="browse-delivery-method-content px-32px py-24px lg:p-40px">
        <div class="font-semibold text-20px leading-28px">
          Email or Text
        </div>
        <p class="mt-16px">
          Delivered in a customized e-greeting card instantly or scheduled for a future date.
        </p>
      </div>
    </GCard>

    <GCard rounded shadow class="browse-delivery-method browse-send-by-mail">
      <div class="browse-delivery-method-header"/>
      <div class="browse-delivery-method-content px-32px py-24px lg:p-40px">
        <div class="font-semibold text-20px leading-28px">
          Send by Mail
        </div>
        <p class="mt-16px">
          Printed and shipped to you or the recipient in a greeting card via first-class or priority mail.
        </p>
      </div>
    </GCard>

    <GCard rounded shadow class="browse-delivery-method browse-send-by-print">
      <div class="browse-delivery-method-header"/>
      <div class="browse-delivery-method-content px-32px py-24px lg:p-40px">
        <div class="font-semibold text-20px leading-28px">
          Print at Home
        </div>
        <p class="mt-16px">
          Print and fold yourself so you have a gift in hand in just minutes.
        </p>
      </div>
    </GCard>
  </GContainer>
</template>

<style lang="scss">
.browse-delivery-method {
  @apply w-full lg:w-280px p-0;

  .browse-delivery-method-header {
    background-size: cover;
    border-radius: 8px 8px 0 0;
    height: 180px;
  }

  &.browse-send-by-email-text .browse-delivery-method-header {
    background-image: v-bind("howItWorksLaptopUrl");
  }

  &.browse-send-by-mail .browse-delivery-method-header {
    background-image: v-bind("howItWorksMailboxUrl");
  }

  &.browse-send-by-print .browse-delivery-method-header {
    background-image: v-bind("howItWorksPrinterUrl");
  }
}
</style>
